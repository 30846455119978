import React from "react"
import "../styles/styles.styl"
import "../styles/tufte-sidenotes.styl"
import classes from "../styles/index.module.styl"

import { graphql, Link } from "gatsby"
import Layout from "../hoc/Layout"

const Home = (props) => {

  let recentNotesArr = []
  props.data.allMdx.edges.forEach(edge => {

    recentNotesArr.push(
      <Link key={ `recent-notes_${edge.node.fields.slug}` } to={ `/${edge.node.fields.slug}` }>
        { edge.node.fields.pageTitle }
        <div>{ edge.node.fields.pageType } / { edge.node.frontmatter.date }</div>
      </Link>
    )
  })

  return (
    <Layout location={ props.location } pageTitle="Vincent's 🌱">
      <div className={ classes.topContainer }>
        <div className={ classes.introduction }>
          <p>Hey! This is Vincent's <Link to='/digital-garden'>digital garden</Link>.</p>
          <p>
            I'm currently looking into digital tools, spirituality and <Link to="/why-shit-is-fucked-up-and-what-we-might-do-about-it">understanding why shit is fucked and what we could do about it</Link>.
          </p>
          <p>
            What exists here are still-in-the-oven ideas, thought sprouts, and ongoing, out-in-the-open workbenches.
          </p>
          <p>I strongly recommend looking at the <Link to='/manual'><em>Manual</em></Link> page to understand how this site works. Feel free to <a href="https://tinyletter.com/vincent-li" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">subscribe to the newsletter</a> to know when I update things.</p>
        </div>

        <div className={ classes.sidebarContainer }>

          <div className={ classes.sidebarListContainer }>
            <h2> Highlights </h2>
            <div className={ classes.sidebarList }>

              <Link to="/modelling-reality">Modelling Reality</Link>
              <Link to="/simulation">Simulation</Link>
              {/* <Link to="/spio_model">SPIO Model</Link> */ }

            </div>
          </div>

          <div className={ classes.sidebarListContainer }>
            <h2> Recent Notes </h2>

            <div className={ classes.sidebarList }>

              { recentNotesArr }

              <Link className={ classes.visitNotes } to="/notes"><span>Visit Notes</span></Link>

            </div>
          </div>

        </div>

      </div>


    </Layout >
  )
}

export default Home

export const query = graphql`
  query {
    allMdx(
      sort:{
        fields:[frontmatter___date]
        order: DESC
      }
      limit: 5
    ) {
      edges {
          node {
            frontmatter {
              date(formatString: "YYYY-MM-DD")
            }
            fields {
              slug 
              pageType
              pageTitle
            }
          }
        }
      }
  }
`
